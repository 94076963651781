import React, { useEffect, useState } from 'react';
import { loginForm } from '../Api'; // Import the loginUser function
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useCookies } from 'react-cookie';
import useLogout from '../hooks/logout';
import logo from '../assets/img/logo.jpg';





function Login() {
  const [formData, setFormData] = useState({
    user: '',
    pass: '',
  });
  const [loginData, setLoginData] = useState<any>(); // Replace 'number' with the appropriate type
  const navigate = useNavigate(); // Initialize the navigate function
  const [cookies, setCookie] = useCookies(['access_token']);
  const logout = useLogout();



  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {

      const requestData = {
        params: formData,
      };

      await loginForm('login', requestData).then(
        (response: any) => {
          setLoginData(response);
          const receivedToken = response.data.key;
          const expiryDate = new Date(Date.now() + 3600000*72);
          setCookie('access_token', receivedToken, { path: '/', expires: expiryDate });
           if (response.status == 200) {
            navigate('/home');

          }
        }
      );

    } catch (error) {
      console.error('Error :', error);
    }
  };

 


  return (

    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">


      {loginData && (
        <div>

          <pre>{loginData?.data?.msg}  </pre>
        </div>
      )}

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm ">

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px] ">
          <div className="bg-gradient-to-br from-white to-indigo-50 px-6 py-12 shadow sm:rounded-lg sm:px-12 h-full">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto   w-24"
            src={logo} 
            alt="Your Company"
          />
         
        </div>
            <h2 className="mb-16 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Login to your account
            </h2>

            <form onSubmit={handleSubmit}>



              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="user"
                    type="text"
                    name="user"
                    value={formData.user}
                    onChange={handleChange}
                    required
                    className="block p-2 w-full h-10 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="text-sm">
                    <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Forgot password?
                    </a>
                  </div>
                </div>
                <div className="mt-2">
                  <input
                    id="pass"
                    type="password"
                    name="pass"
                    value={formData.pass}
                    onChange={handleChange}
                    autoComplete="current-password"
                    required
                    className="block p-2 w-full h-10 rounded-md border-0   text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>


              <div className='mt-4'>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* <button onClick={logout}>Logout</button> */}
      </div>
    </div>

  );
}

export default Login;
