import axios from 'axios';
import { useCookies } from 'react-cookie';
 
export async function loginForm(url : string, postData : any) {    

  try {
    const apiUrl = process.env.REACT_APP_BACKEND_URL+'/'+url;
    const response = await axios.post(apiUrl, postData, {
      headers: {
        'Content-Type': 'application/json', 

      },
    });

    return response.data; // Return the response data
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
}

export async function postForm(url : string, postData : any ,token : string) {    

  try {
    const apiUrl = process.env.REACT_APP_BACKEND_URL+'/'+url;
    const response = await axios.post(apiUrl, postData, {
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`, // Use template literals to concatenate the token

      },
    });

    return response.data; // Return the response data
  } catch (error) {
    throw error; // Rethrow the error to be handled by the caller
  }
}
