import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import useFetch from '../hooks/useFetch';
import { useCookies } from 'react-cookie';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';

const Candidate = () => {
  const [cp, setCp] = useState(1);
  const [rpp, setRpp] = useState(10);
  const apiUrl = 'users';
  const loader = useRef(null);
  const [cookies] = useCookies();
  const accessToken = cookies['access_token'];
  const userParams = useMemo(() => {
    return { params: { cp: cp, rpp: rpp } };
  }, [cp, rpp]); // cp and rpp are the dependencies

  // const userParams = {
  //   params: {
  //     cp: 1,
  //     rpp: 10
  //   }
  // };
  const { data, loading, error } = useFetch(apiUrl, accessToken, userParams);


  const handleChange = () => {
    setCp(cp + 1);
    console.log(data?.data);
  };

  // const handleObserver = useCallback((entities: any) => {
  //   const target = entities[0];
  //   if (target.isIntersecting) {
  //     // load more data here
  //    // handleChange();
  //   }
  // }, []);

  // useEffect(() => {
  //   const option = {
  //     root: null,
  //     rootMargin: "20px",
  //     threshold: 0
  //   };
  //   const observer = new IntersectionObserver(handleObserver, option);
  //   if (loader.current) observer.observe(loader.current);
  //   console.log("candidate-page",data);
  //   return () => {
  //     if (loader.current) observer.unobserve(loader.current);
  //   };
  // }, []);

  return (
    <div className="App">


      <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8 ">
        <div className="rounded-lg  bg-gray-100 py-6 shadow sm:px-6 ">
          <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">

            {data?.data?.rows?.map((user: any) => (
              <li key={user.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <div className="flex-1 truncate ">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text-sm font-medium text-gray-900">{user.fullname}</h3>
                      <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        {user.role}-{user.id}
                      </span>
                    </div>
                    <p className="mt-1 truncate text-sm text-gray-500">{user.username}</p>
                  </div>
                  <div className="h-10 w-10 flex-shrink-0 rounded-full bg-indigo-100 flex justify-center items-center">
                    <span className="text-gray-600 font-bold">{user.id}</span>
                  </div>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1">
                      <a
                        href={`mailto:${user.email}`}
                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                      >
                        <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        Email
                      </a>
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                      <a
                        href={`tel:${user.telephone}`}
                        className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                      >
                        <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        Call
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          {Math.min(cp * userParams.params.rpp, data?.data?.rowcount)} of {data?.data?.rowcount} records
        </div>
      </div>
      <div className="flex justify-center items-center">

        <button
          onClick={() => {
            if (cp < data?.data?.pagecount) {
              handleChange();
              window.scrollTo(0, document.body.scrollHeight);
            }
          }}
          disabled={cp >= data?.data?.pagecount}

          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {loading ? 'Loading...' : 'Load More'}
        </button>
      </div>
      {error && <p>Error!</p>}
      <div ref={loader} />
    </div>
  );
};

export default Candidate;