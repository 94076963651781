import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { UserProvider } from './UserContext';
import Home from './components/Home';
import About from './components/About';
import Navbar from './components/Navbar';
import People from './components/People';
import Login from './components/Login';
import Users from './components/Users';
import Candidate from './components/Candidate';

import { useCookies } from 'react-cookie';

function App() {
  const [cookies] = useCookies(['access_token']);
  const isLoggedIn = !!cookies.access_token;
  const location = useLocation();

  return (
    <UserProvider>
      {location.pathname !== '/login' && isLoggedIn && <Navbar />}
      <Routes>
        <Route path="/home" element={isLoggedIn ? <Home /> : <Navigate to="/login" />} />
        <Route path="/people" element={<People />} />
        <Route path="/about" element={<About />} />
        <Route path="/login" element={<Login />} />
        <Route path="/candidate" element={<Candidate />} />
        <Route path="/users" element={isLoggedIn ? <Users /> : <Navigate to="/login" />} />
        <Route path="*" element={isLoggedIn ? <Home /> : <Navigate to="/login" />} />
      </Routes>
    </UserProvider>
  );
}

function MainApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default MainApp;