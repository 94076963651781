import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useCookies } from 'react-cookie';

// Define the type for your user context
type UserContextType = {
  userLoggedIn: boolean;
  login: () => void;
  logout: () => void;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserProvider({ children }: { children: ReactNode }) {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [cookies, setCookie] = useCookies(['access_token']);

  const login = () => {
    // Implement your login logic here and set userLoggedIn to true
    if(cookies.access_token){
    setUserLoggedIn(true);
    }
  };

  const logout = () => {
    // Implement your logout logic here and set userLoggedIn to false
    setUserLoggedIn(false);
  };

  return (
    <UserContext.Provider value={{ userLoggedIn, login, logout }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
}
