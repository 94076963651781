import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

function useLogout() {
  const [, , removeCookie] = useCookies(['access_token']);

  const logout = () => {
    removeCookie('access_token');
  };

  useEffect(() => {
    logout();
  }, []);

  return logout;
}

export default useLogout;