import { postForm } from '../Api';


const apiUrl = process.env.REACT_APP_BACKEND_URL;
const requestData = {
  params: {
    cp: 1, 
    rpp: 10 
  }
};
export async function getUsers(token: string, params: any) {
  const response = await postForm('users', params, token);

   return response;
}