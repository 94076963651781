import React, { useState, useEffect, lazy, Suspense } from 'react';
import { postForm } from '../Api';
import { getUsers } from '../api/users';
import { FaUser, FaSpinner, FaSearch } from 'react-icons/fa';
import { useCookies } from 'react-cookie';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import Paginate from 'react-paginate';
import axios from 'axios';

const UserList = lazy(() => import('./UsersList'));


export default function Users() {
  const [userData, setUserData] = useState<any>();
  const [loading, setLoading] = useState<any>();
  const [cookies] = useCookies(['access_token']);
  const accessToken = cookies['access_token'];
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  function getInitials(fullname: string): string {
    const words = fullname.split(' ');
    const initials = words.map((word) => word.charAt(0));
    return initials.join('');
  }

   
  const handlePageChange = (selectedObject: { selected: number }) => {
    setPage(selectedObject.selected + 1);
  };
  useEffect(() => {
    const source = axios.CancelToken.source();

    const pageData = {
      params: {
        cp: page,
        rpp: pageSize
      }
    };
    getUsers(accessToken, pageData).then((data) => setUserData(data));
    return () => {
      source.cancel('Operation cancelled by the user.');
    };
  }, [accessToken, page, pageSize]); // The empty dependency array ensures this runs only once when the component mounts


  return (
    <div className="min-h-full ">
      <div className="bg-gray-700 pb-32">

        <header className="py-7">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-white">Users</h1>
          </div>
          
        </header>
      </div>

      <main className="-mt-32">
        <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8 ">
          <div className="rounded-lg  bg-gray-100 py-6 shadow sm:px-6 ">

            <Suspense fallback={<div>Loading...</div>}>
              <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {userData?.data?.rows?.map((user: any) => (
                  <li key={user.email} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                    <div className="flex w-full items-center justify-between space-x-6 p-6">
                      <div className="flex-1 truncate ">
                        <div className="flex items-center space-x-3">
                          <h3 className="truncate text-sm font-medium text-gray-900">{user.fullname}</h3>
                          <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            {user.role}-{user.id}
                          </span>
                        </div>
                        <p className="mt-1 truncate text-sm text-gray-500">{user.username}</p>
                      </div>
                      <div className="h-10 w-10 flex-shrink-0 rounded-full bg-indigo-100 flex justify-center items-center">
                        <span className="text-gray-600 font-bold">{getInitials(user.fullname)}</span>
                      </div>
                    </div>
                    <div>
                      <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="flex w-0 flex-1">
                          <a
                            href={`mailto:${user.email}`}
                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                          >
                            <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            Email
                          </a>
                        </div>
                        <div className="-ml-px flex w-0 flex-1">
                          <a
                            href={`tel:${user.telephone}`}
                            className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                          >
                            <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            Call
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="flex justify-center items-center pt-4 ">
  <Paginate
    className="flex items-center flex-wrap" // added flex-wrap
    pageCount={userData?.data?.pagecount}
    pageRangeDisplayed={4}
    marginPagesDisplayed={1}
    onPageChange={handlePageChange}
    containerClassName={'flex space-x-2 sm:space-x-5'} // changed this line
    activeClassName={'bg-blue-50 text-blue-800 px-2 py-1 sm:px-4 sm:py-2'} // changed this line
    previousClassName={'bg-gray-50 px-2 py-1 sm:px-4 sm:py-2 rounded-l-lg'} // changed this line
    nextClassName={'bg-gray-50 px-2 py-1 sm:px-4 sm:py-2 rounded-r-lg'} // changed this line
    pageClassName={'bg-gray-50 px-2 py-1 sm:px-4 sm:py-2'} // changed this line
    forcePage={page - 1}
  />
</div>
            </Suspense>

          

        
          </div>
        </div>
      </main>
    </div>

  )
}