import { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';

const people = [
    {
        name: 'Calvin Hawkins',
        email: 'cali7vin.hawkins@example.com',
        image:
            'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    {
        name: 'Kristen Ramos',
        email: 'kruuiisten.ramos@example.com',
        image:
            'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    

]

export default function People() {
    const [sdfsdf] = useCookies(['access_token']);

     const [peopleData, setPeopleData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        // Access the "access_token" cookie
        console.log('Access Token:', sdfsdf.access_token);
 
        
    }, [sdfsdf]);

    return (
        
        <div className="min-h-full">
            <div className="bg-gray-100 pb-5">
                <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8 pt-5">
                    <ul className="divide-y divide-gray-200">
                        {people.map((person) => (
                            <li key={person.email} className="py-4 flex">
                                <img className="h-10 w-10 rounded-full" src={person.image} alt="" />
                                <div className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">{person.name}</p>
                                    <p className="text-sm text-gray-500">{person.email}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}