import { useState, useEffect,useRef, useMemo } from 'react';
import axios from 'axios';
 

 

const useFetch = (url: string,accessToken : string, userParams:any) => {

   const apiUrl = process.env.REACT_APP_BACKEND_URL + '/' + url;
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const initialRender = useRef(userParams.cp);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (userParams.cp !== 1 || !data) {
      setLoading(true);
      setError(null);
      axios.post(apiUrl, userParams, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        cancelToken: source.token // Pass the cancel token to the request
      })
        .then((res) => {
          setData((oldData: any) => ({
            ...oldData,
            data: {
                ...oldData?.data,
                rowcount: res?.data?.data?.rowcount,
                pagecount: res?.data?.data?.pagecount,
                rows: [...(oldData?.data?.rows || []), ...(res.data?.data?.rows || [])]
              }
          }));
          setLoading(false);
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.log('Request canceled', err.message);
          } else {
            setError(err);
            setLoading(false);
          }
        });
    }

    return () => {
      source.cancel(data);
    };
  }, [url, userParams]);

  return { data, loading, error };
};

export default useFetch;